// template file for hostels page

import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import {
  Header,
  FlexGallery,
  FlexGalleryWrapper
} from '../components/layout/index.js';
import { Section } from '../components/common/index.js';
import useTranslations from '../components/layout/useTranslations';

const DestinationsPage = ({ data }) => {
  const {
    baseYourself,
    australianHostels,
    nzHostels,
    sunset
  } = useTranslations();

  const au = data.australia.edges;
  const nz = data.nz.edges;

  const hopimage = data.hop.childImageSharp.fluid;
  const hop = {
    node: {
      featuredImage: {
        alt: 'Bedhopper Image',
        fluid: hopimage
      },
      id: 'bedhopper',
      slug: 'deals/bed-hopper',
      title: ''
    }
  };
  var aucombined = au.concat(hop);
  var nzcombined = nz.concat(hop);

  return (
    <>
      <Helmet
        title="Backpacker Hostels in Australia and New Zealand"
        meta={[
          {
            name: 'description',
            content: 'Find Austrlaia and New Zealands top backpacker hostels.'
          }
        ]}
      />
      <Header
        backgroundImage={data.file.childImageSharp.fluid}
        pageTitle={baseYourself}
        propertyName="Base Magnetic Island"
        caption={sunset}
      />

      <Section>
        <h2>{australianHostels}</h2>

        <FlexGalleryWrapper laptopWidth="270" mobileLWidth="160">
          {aucombined.map(
            ({ node: hostel }, index) =>
              hostel.id === 'bedhopper' ? (
                <FlexGallery
                  title={hostel.title}
                  alt={hostel.title}
                  fluid={hostel.featuredImage.fluid}
                  key={hostel.id}
                  alt={hostel.featuredImage.alt}
                  index={index}
                  url={`/${hostel.slug}`}
                  id={hostel.id}
                />
              ) : (
                <FlexGallery
                  title={hostel.title}
                  alt={hostel.title}
                  fluid={hostel.featuredImage.fluid}
                  key={hostel.id}
                  alt={hostel.featuredImage.alt}
                  index={index}
                  url={`/hostels/${hostel.slug}`}
                  id={hostel.id}
                />
              )
          )}
        </FlexGalleryWrapper>
      </Section>
      {nzcombined.length > 1 && (
        <Section>
          <h2>{nzHostels}</h2>

          <FlexGalleryWrapper laptopWidth="270" mobileLWidth="160">
            {nzcombined.map(
              ({ node: hostel }, index) =>
                hostel.id === 'bedhopper' ? (
                  <FlexGallery
                    title={hostel.title}
                    alt={hostel.title}
                    fluid={hostel.featuredImage.fluid}
                    key={hostel.id}
                    alt={hostel.featuredImage.alt}
                    index={index}
                    url={`/${hostel.slug}`}
                    id={hostel.id}
                  />
                ) : (
                  <FlexGallery
                    title={hostel.title}
                    alt={hostel.title}
                    fluid={hostel.featuredImage.fluid}
                    key={hostel.id}
                    alt={hostel.featuredImage.alt}
                    index={index}
                    url={`/hostels/${hostel.slug}`}
                    id={hostel.id}
                  />
                )
            )}
          </FlexGalleryWrapper>
        </Section>
      )}
    </>
  );
};

export default DestinationsPage;

export const query = graphql`
  query hostelIndexQuery($locale: String!) {
    file(relativePath: { eq: "backpacker-destinations-australia-nz.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hop: file(relativePath: { eq: "bedhopper.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    australia: allDatoCmsHostel(
      filter: { locale: { eq: $locale }, country: { eq: "Australia" } }
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            alt
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    nz: allDatoCmsHostel(
      filter: { locale: { eq: $locale }, country: { eq: "New Zealand" } }
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            fluid(
              maxWidth: 300
              maxHeight: 300
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
